@import 'Styles/Base/includes';

/**
*
* GalleryModal
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.GalleryModal {
    $root: &;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: z(Modal__Backdrop);

    &__Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        height: 100vh;
        z-index: z(Modal);
        -webkit-overflow-scrolling: touch;
    }

    &__ImageContainer {
        @include u-wrap;

        text-align: center;
    }

    &__Image {
        width: auto;
        max-width: 90vw;
        height: auto;
        max-height: 90vh;
        transition: opacity .3s .2s ease-in-out;

        @include media(md) {
            max-width: 80vw;
            max-height: 80vh;
        }

        #{$root}--Visible & {
            opacity: 1;
        }

        #{$root}--Hidden & {
            opacity: 0;
        }
    }

    &__ImageDescription {
        margin-bottom: 5px;
        max-width: 600px;
        text-align: center;
        margin: auto;
        line-height: 21px;
    }

    &__Btn {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1px);
            display: block;
            height: 3px;
            margin: 0 auto;
        }

        &::before {
            transform: rotate(45deg);
            transform-origin: center;
        }

        &::after {
            transform: rotate(-45deg);
            transform-origin: center;
        }

        @include media(sm) {
            width: 48px;
            height: 48px;
        }
    }

}

@import 'Styles/Dansskolanentre/includes';

/**
*
* CardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardNews.Base.module';

.CardNews {
    $root: &;

    &__Container {
        border-radius: 8px;
        overflow: hidden;

        @include media(sm) {
            border-radius: 12px;
        }
    }

    &__Content {
        padding: 20px 16px 20px;
        background-color: $red-5;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @include media(sm) {
            padding: 24px 24px 28px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }

    &__Category {
        margin-bottom: 8px;
        font-size: 1.4rem;
        font-weight: $medium;
        letter-spacing: .7px;
        line-height: 1.17;
        color: $red-60;
    }

    &__Title {
        font-size: 2.4rem;
        font-weight: $bold;
        line-height: 1.37;
        color: $text-color;

        @include media(sm) {
            font-size: 2.6rem;
        }

        #{$root}:hover & {
            color: $primary-color;
            text-decoration: underline;
        }
    }

    &__Text {
        margin-top: 16px;
        font-size: 1.8rem;
        font-weight: $light;
    }

    &__Date {
        margin-top: 32px;
        color: $grey-70;
        font-weight: $light;

        @include media(sm) {
            margin-top: 48px;
        }
    }

    &__Author {
        color: $grey-70;
        font-weight: $light;

        &::before {
            top: -1px;
            width: 1px;
            height: 20px;
            border-radius: 0;
            background-color: $grey-70;
        }
    }

    &__Type {
        color: $red-60;
        font-weight: $light;

        @include media(sm) {
            margin-top: 29px;
        }

        &::after {
            top: -1px;
            width: 1px;
            height: 20px;
            border-radius: 0;
            background-color: $grey-70;
        }
    }
}

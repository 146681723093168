@import 'Styles/Kulturama/includes';

/**
*
* SubSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SubSubjectList {
    $root: &;
    background: palette(black, 5);
    border-bottom: 1px solid palette(black, 16);

    &__Container {
        @include u-wrap(site);
        padding-top: 40px;
        padding-bottom: 55px;
        text-align: center;

        #{$root}--Small & {
            padding-top: 40px;
        }

        @include media(sm) {
            padding-top: 60px;
            padding-bottom: 70px;
        }

        @include media(md) {
            padding-top: 70px;
            padding-bottom: 80px;
        }
    }

    &__Title {
        margin-bottom: 20px;
        font-size: 20px;
        text-align: left;
        font-weight: $bold;
        @include media(sm) {
            margin-bottom: 30px;
        }
    }

    &__Categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;

        @include media(sm) {
            margin-left: -20px;
        }
    }

    &__Category {
        display: flex;
        overflow: hidden;
        background: white;
        border-radius: 4px;
        margin-bottom: 10px;
        position: relative;
        transition: all .3s ease-in-out;
        width: 100%;

        @include media(sm) {
            width: calc(#{percentage(1/3)} - 20px);
            margin-left: 12px;
            margin-bottom: 12px;
        }

        &:hover {
            box-shadow: 0 4px 10px rgba(black, 0.3);
        }

        &--Hidden {
            position: absolute;
            overflow: hidden;
            width: 0;
            height: 0;
            margin: 0;
            opacity: 0;
            z-index: z(behind);
        }
    }

    &__CategoryWrapper {
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
    }

    &__ImageContainer {
        position: relative;
        border-radius: 4px 0px 0px 4px;
        width: 65px;
        min-height: 65px;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        flex: 0 0 auto;
        overflow: hidden;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .3s ease-in-out;

        #{$root}__Category:hover & {
            transform: scale(1.2, 1.2);
        }
    }

    &__Icon {
        display: none;
    }

    &__LinkTitle {
        text-align: left;
        display: flex;
        align-items: center;
        padding: 11px 20px 13px 15px;
        font-size: 1.6rem;
        hyphens: auto;
        transition: all .3s ease-in-out;
        flex: 1 0 auto;
        width: calc(100% - 65px);

        &:before {
            content: '';
            background-image: url('#{$basepath}img/bg-icons/kulturama/arrow--pink.svg');
            margin-right: 10px;
            background-repeat: no-repeat;
            background-size: contain;
            color: black;
            width: 16px;
            height: 16px;
            margin-top: 5px;
            flex: 0 0 auto;
        }

        #{$root}__Category:hover & {
            transform: translate(8px, 0px);
        }
    }

    &__Button {
        @include u-button(border);
        position: relative;
        margin: 30px auto 0;
        z-index: 100;

        &--Hidden {
            display: none;
        }
    }
}

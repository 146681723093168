@import 'Styles/Pahlmans/includes';

/**
*
* CardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardNews.Base.module';

.CardNews {
    $root: &;

    padding: 20px 20px 16px;
    background-color: white;
    transition: box-shadow .2s ease-in-out;

    &:hover {
        box-shadow: 3px 2px 9px 0 rgba(black, 0.1);
    }

    &__Container {
        + svg {
            display: block;
            opacity: 0;
            position: absolute;
            stroke: $primary-color;
            right: 20px;
            bottom: 24px;
            width: 25px;
            transition: opacity .2s ease-in-out;

            #{$root}:hover & {
                opacity: 1;
            }
        }
    }

    &__Category {
        font-size: 1.2rem;
        font-weight: $bold;
        margin-bottom: 6px;
        color: $primary-color;
    }

    &__Content {
        padding-top: 25px;
    }

    &__Title {
        font-family: $font-heading;
        font-size: 2.6rem;
        line-height: 1.2;
    }

    &__Text {
        margin-top: 7px;
    }

    &__Date {
        color: $primary-color;

        @include media(sm) {
            margin-top: 29px;
        }
    }

    &__Type {
        color: $primary-color;

        @include media(sm) {
            margin-top: 29px;
        }

        &::after {
            background-color: $primary-color;
        }
    }

    &__Author {
        color: $primary-color;

        &::before {
            background-color: $primary-color;
        }
    }
}

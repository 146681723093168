@import 'Styles/Base/includes';

/**
*
* EventSearchButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSearchButton {
    $root: &;

    display: block;
    margin-left: 10px;
    margin-bottom: 10px;
    height: 40px;
    font-size: 1.4rem;
    line-height: 40px;
    text-align: center;

    &__Input {
        display: none;
    }

    &__Label {
        position: relative;
        display: block;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 1.4rem;
        background-color: white;
        cursor: pointer;
        transition: color .2s, border-color .2s;

        #{$root}__Input:checked ~ & {
            color: white;
        }
    }
}

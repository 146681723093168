@import 'Styles/Base/includes';

/**
*
* CardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardNews {
    $root: &;

    position: relative;
    display: flex;
    flex: 1 0 auto;
    width: 100%;

    &__Container {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        background: white;

        + svg {
            display: none;
        }
    }

    &__ImageContainer {
        flex: 0 0 auto;
        position: relative;
        min-height: 212px;
        overflow: hidden;

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: white;
            opacity: .75;
            z-index: 1;

            #{$root}--Video & {
                display: block;
            }
        }

        svg {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: -4px;
            margin: auto;
            z-index: 1;

            #{$root}--Video & {
                display: block;
            }
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Category {
        text-transform: uppercase;
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        background: white;
    }

    &__Title {
        font-size: 2.2rem;
        line-height: 1.3;
        word-break: break-word;
        transition: color .2s ease-in-out;
    }

    &__Text {
        display: none;
        margin-top: 10px;
        font-size: 1.6rem;

        @include media(sm) {
            display: block;
        }
    }

    &__Meta {
        margin-top: auto;
    }

    &__Date {
        display: inline-block;
        margin-top: 15px;
        font-size: 1.4rem;
    }

    &__Author {
        display: inline-block;
        font-size: 1.4rem;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            top: -2px;
            width: 3px;
            height: 3px;
            vertical-align: middle;
            margin: 0 8px;
        }
    }

    &__Type {
        display: inline-block;
        margin-top: 15px;
        font-size: 1.4rem;

        &::after {
            content: '';
            display: inline-block;
            position: relative;
            top: -2px;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            vertical-align: middle;
            margin-left: 9px;
            margin-right: 9px;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }
}

@import 'Styles/Pahlmans/includes';

/**
*
* Gallery
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Gallery.Base.module';

.Gallery {
    $root: &;

    &__ImageContent {

        &--RestMobile,
        &--RestDesktop {
            background-color: palette(black, 4);
        }
    }

    &__ShowMore {

        span:first-child {
            color: $primary-color;
            font-weight: $bold;
        }

        span:last-child {
            color: palette(black, 95);
            font-weight: $medium;
        }
    }

    &__Image {
        &::after {
            background-color: rgba($primary-color, .7);
        }
    }
}

@import 'Styles/Base/includes';

.SliderCardNews {
    $root: &;

    &:not(#{$root}--HasBg) {
        margin-top: 40px;
        margin-bottom: 40px;

        @include media(sm) {
            margin-top: 64px;
            margin-bottom: 64px;
        }

        @include media(lg) {
            margin-top: 96px;
            margin-bottom: 96px;
        }
    }

    &__Container {
        @include u-wrap(site);

        #{$root}--HasBg & {
            padding-top: 40px;
            padding-bottom: 20px;

            @include media(sm) {
                padding-top: 80px;
                padding-bottom: 60px;
            }
        }
    }

    &__Button {
        text-align: center;
        margin-top: 20px;
    }
}

@import 'Styles/Medborgarskolan/includes';

/**
*
* Gallery
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Gallery.Base.module';

.Gallery {
    $root: &;

    &__Content {
        margin-left: 0;
        gap: 16px;
        @include media(sm) {
            gap: 24px;
        }
    }

    &__ImageContent {
        width: calc(50% - 8px);
        margin-bottom: 0;
        margin-left: 0;

        @include media(sm) {
            width: calc((100% / 3) - 16px);
        }

        &--RestMobile,
        &--RestDesktop {
            background-color: $blue;
        }
    }

    &__ShowMore {
        span:first-child {
            color: white;
            font-size: 3rem;
            font-weight: $bold;

            @include media(md) {
                font-size: 3.8rem;
            }
        }

        span:last-child {
            margin-top: 4px;
            color: white;
            font-size: 1.2rem;
            font-weight: $bold;

            @include media(md) {
                font-size: 1.4rem;
            }
        }
    }

    &__Image {
        &::after {
            background-color: rgba($blue, 0.64);
        }
    }
}

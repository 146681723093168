@import 'Styles/Medborgarskolan/includes';

/**
*
* SubSubjectList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SubSubjectList {
    $root: &;
    background: $blue-20;

    &__Container {
        @include u-wrap(site);

        padding-top: 48px;
        padding-bottom: 48px;
        text-align: center;

        #{$root}--Small & {
            padding-top: 40px;
        }

        @include media(md) {
            padding-top: 70px;
            padding-bottom: 88px;
        }

        #{$root}--NoTopSpacing & {
            padding-top: 0 !important;
        }
    }

    &__Title {
        margin-bottom: 20px;
        font-size: 3.4rem;
        text-align: left;
        font-weight: $bold;
        color: $blue;

        @include media(sm) {
            margin-bottom: 30px;
        }
    }

    &__Categories {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-bottom: -20px;

        @include media(sm) {
            margin-left: -24px;
            margin-bottom: -24px;
        }
    }

    &__Category {
        display: flex;
        align-items: center;
        padding: 12px;
        background: white;
        border-radius: 8px;
        width: calc(100% - 20px);
        margin-left: 20px;
        margin-bottom: 20px;

        @include media(xs) {
            width: calc(50% - 20px);
        }

        @include media(sm) {
            width: calc(#{percentage(1/3)} - 24px);
            margin-left: 24px;
            margin-bottom: 24px;
        }

        &--Hidden {
            position: absolute;
            overflow: hidden;
            width: 0;
            height: 0;
            margin: 0;
            opacity: 0;
            z-index: z(behind);
        }
    }

    &__CategoryWrapper {
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
    }

    &__ImageContainer {
        flex: 0 0 auto;
        position: relative;
        width: 56px;
        height: 56px;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $coral-20;
        border-radius: 100%;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background: $coral-20 url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow-handdrawn--blue.svg') no-repeat -40px center;
            background-size: 24px;
            transition: background-position $transition;
            backdrop-filter: blur(100px) brightness(1); // Hack to remove small outline shown from Image background
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background: inherit;
            transform: translateX(0);
            transition: transform $transition, opacity $transition;
            backdrop-filter: blur(100px) brightness(1); // Hack to remove small outline shown from Image background

            #{$root}__Category--IsIcon & {
                display: none;
            }
        }

        #{$root}__Category:hover & {
            &::before {
                background-position: 16px center;
            }

            &::after {
                opacity: 0;
            }
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        transform: translateX(0);
        transition: transform $transition, opacity $transition;
        overflow: hidden;
        z-index: 1;

        #{$root}__Category:hover & {
            opacity: 0;
        }
    }

    &__Icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 32px;
        height: 32px;
        margin: auto;
        z-index: 1;
        transform: translateX(0);
        transition: transform $transition, opacity $transition;

        @include media(sm) {
            width: 44px;
            height: 44px;
        }

        #{$root}__Category:hover & {
            opacity: 0;
            transform: translateX(56px);

            @include media(md) {
                transform: translateX(64px);
            }
        }
    }

    &__LinkTitle {
        text-align: left;
        display: flex;
        align-items: center;
        padding: 0px 8px 0px 15px;
        font-size: 2.4rem;
        color: $blue;
        hyphens: auto;
        transition: transform $transition;

        #{$root}__Category:hover & {
            transform: translateX(6px);
        }
    }

    &__Button {
        @include u-button(secondary);
        position: relative;
        margin: 30px auto 0;
        z-index: 100;

        &--Hidden {
            display: none;
        }
    }
}

@import 'Styles/Tillskararakademin/includes';

/**
*
* CardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardNews.Base.module';

.CardNews {
    $root: &;

    border: 1px solid palette(black, 30);

    &__Category {
        font-size: 1.2rem;
        font-weight: $medium;
        margin-bottom: 6px;
        color: palette(green);
    }

    &__Content {
        padding: 25px 20px 18px;
    }

    &__Title {
        font-family: $font-heading;
        font-size: 2.6rem;
        font-weight: $bold;
        line-height: 1.2;

        #{$root}:hover & {
            color: palette(green);
            text-decoration: underline;
        }
    }

    &__Text {
        margin-top: 7px;
    }

    &__Date {
        @include media(sm) {
            margin-top: 29px;
        }
    }

    &__Type {
        @include media(sm) {
            margin-top: 29px;
        }

        &::after {
            background-color: palette(green);
        }
    }

    &__Author {
        &::before {
            background-color: palette(green);
        }
    }
}

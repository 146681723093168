@import 'Styles/Dansskolanentre/includes';

/**
*
* Gallery
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Gallery.Base.module';

.Gallery {
    $root: &;

    margin: 40px 0;

    @include media(md) {
        margin: 60px 0;
    }

    &__Container {
        margin: 0;
    }

    &__ImageContent {
        overflow: hidden;

        &--RestMobile,
        &--RestDesktop {
            background-color: $red-5;
        }
    }

    &__ShowMore {
        span:first-child {
            color: $primary-color;
            font-weight: $bold;
        }

        span:last-child {
            color: $text-color;
            font-size: 1.3rem;
            font-weight: $regular;
        }
    }

    &__Image {
        &::after {
            background-color: rgba($primary-color, .8);
            background-image: url(#{$basepath}img/bg-icons/dansskolanentre/search--white.svg);
            background-size: 24px 24px;
        }
    }

}

@import 'Styles/Kulturama/includes';

/**
*
* CardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardNews.Base.module';

.CardNews {
    $root: &;

    padding: 0 0 32px;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 32px);
        height: 32px;
        background: white;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        border: 32px solid transparent;
        border-left-width: 0;
        border-bottom-width: 0;
        border-top-color: white;
    }

    &__ImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            width: 50px;
            height: 50px;
            background: url('#{$basepath}img/bg-icons/kulturama/play--white.svg') no-repeat center center;
            background-size: contain;
            opacity: 1;
        }

        svg {
            display: none !important;
        }
    }

    &__Category {
        font-size: 1.5rem;
        font-weight: $medium;
        margin-bottom: 10px;
        color: palette(pink);
    }

    &__Content {
        padding: 25px 20px 18px;
        margin-bottom: -32px;
        background: transparent;
        z-index: 1;
    }

    &__Title {
        font-size: 2.2rem;
        line-height: 1.3;

        #{$root}:hover & {
            color: palette(pink);
        }
    }

    &__Date {
        @include media(sm) {
            margin-top: 29px;
        }
    }

    &__Type {
        @include media(sm) {
            margin-top: 29px;
        }

        &::after {
            background-color: palette(pink);
        }
    }

    &__Author {

        &::before {
            background-color: palette(pink);
        }
    }
}

@import 'Styles/Kulturama/includes';

/**
*
* SliderCardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SliderCardNews.Base.module.scss';

.SliderCardNews {
    $root: &;

    &--HasBg {
        background: palette(black, 5);
    }

    &__Link {
        @include u-button(border-arrow);

        svg {
            display: none;
        }
    }
}

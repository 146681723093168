@import 'Styles/Medborgarskolan/includes';

/**
*
* GalleryModal
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'GalleryModal.Base.module';

.GalleryModal {
    $root: &;

    &__Container {
        padding-top: 40px;
        background-color: rgba(black, .9);
    }

    &__ImageContainer {
        padding-top: 42px;
        padding-bottom: 42px;
    }

    &__Image {
        display: block;
        margin-bottom: 14px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: -2px 2px 15px 0 rgba(black, .9);
    }

    &__ImageDescription {
        color: white;
        font-size: 1.4rem;
        font-weight: $normal;
    }

    &__Btn {
        background-color: $blue;

        &::before,
        &::after {
            content: '';
            width: 14px;
            background-color: white;
        }
    }

}

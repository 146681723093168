@import 'Styles/Tillskararakademin/includes';

/**
*
* EventSearchButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButton.Base.module.scss';

.EventSearchButton {
    $root: &;

    &__Label {
        font-weight: $medium;
        border: 2px solid palette(black);

        #{$root}__Input:not(:checked) ~ &:hover {
            color: palette(green);
            border-color: palette(green);
        }

        #{$root}__Input:checked ~ & {
            background: palette(black);
        }
    }
}

@import 'Styles/Dansskolanentre/includes';

/**
*
* SliderCardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SliderCardNews.Base.module.scss';

.SliderCardNews {
    $root: &;

    margin: 40px 0 !important;

    @include media(md) {
        margin: 60px 0 !important;
    }

    &__Container {
        #{$root}--HasBg & {
            padding-top: 0;
            padding-bottom: 0;

            @include media(sm) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &__Link {
        @include u-button(solid large $primary-color);

        svg {
            display: none;
        }
    }

    &__Button {
        margin-top: 4px;
    }
}

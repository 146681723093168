@import 'Styles/Kulturama/includes';

/**
*
* Gallery
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Gallery.Base.module';

.Gallery {
    $root: &;

    &__ImageContent {

        &--RestMobile,
        &--RestDesktop {
            background-color: palette(black, 4);
        }
    }

    &__ShowMore {

        span:first-child {
            color: palette(pink);
            font-weight: $bold;
        }

        span:last-child {
            color: palette(black, 95);
            font-weight: $medium;
        }
    }

    &__Image {
        &::after {
            background-image: url(#{$basepath}img/bg-icons/kulturama/search--white.svg);
            background-size: 25px 25px;
            background-color: rgba(palette(pink), .7);
        }
    }

}

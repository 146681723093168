@import 'Styles/Dansskolanentre/includes';

/**
*
* EventSearchButtons
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButtons.Base.module';

.EventSearchButtons {
    $root: &;

    &__Container {

        @include media(md) {
            padding-bottom: 30px;
        }
    }

    &__Title {
        display: none;
    }

    &__List {

        @include media(md) {
            justify-content: left;
        }
    }
}

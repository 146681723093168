@import 'Styles/Base/includes';

/**
*
* EventSearchButtons
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventSearchButtons {
    $root: &;

    &__Container {
        padding-top: 20px;
        padding-bottom: 20px;

        @include media(md) {
            padding-bottom: 40px;
        }
    }

    &__Title {
        margin-bottom: 16px;
        font-size: 1.6rem;

        @include media(md) {
            margin-top: 8px;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;

        @include media(md) {
            justify-content: center;
        }
    }
}

@import 'Styles/Dansskolanentre/includes';

/**
*
* EventSearchButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButton.Base.module.scss';

.EventSearchButton {
    $root: &;

    margin-left: 12px;
    margin-bottom: 19px;

    &__Label {
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 1.6rem;
        font-weight: $regular;
        line-height: 38px;
        border-radius: 20px;
        border: 1px solid $grey-40;
        background: white;

        #{$root}__Input:hover ~ & {
            border-color: $red-60;
        }

        #{$root}__Input:checked ~ & {
            border: none;
            background: $gradient-color;
        }
    }
}

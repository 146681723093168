@import 'Styles/Kulturama/includes';

/**
*
* EventSearchButtons
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButtons.Base.module';

.EventSearchButtons {
    $root: &;

    background-color: palette(black, 5);

    &__Title {
        display: none;
    }
}

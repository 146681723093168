@import 'Styles/Medborgarskolan/includes';

/**
*
* CardNews
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardNews.Base.module';

.CardNews {
    $root: &;

    // &:hover {
    //     &::before {
    //         opacity: 0.14;

    //         @media (hover: none) {
    //             opacity: 0;
    //         }
    //     }
    // }

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background: black;
    //     opacity: 0;
    //     z-index: 1;
    //     transition: opacity 0.2s ease-in;
    // }

    &__Container {
        z-index: 1;
        border-radius: 8px;
    }

    &__ImageContainer {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &::before {
            width: 64px;
            height: 64px;
            background-color: $blue;
            opacity: 1;
        }
        svg * {
            fill: $blue-20;
        }
    }

    &__Content {
        padding: 20px 24px;
        border-radius: 8px;
    }

    &__Category {
        margin-bottom: 8px;
        color: $blue;
        font-size: 1.4rem;
        font-weight: $bold;
        line-height: 1.52;
        letter-spacing: 1.2px;
    }

    &__Title {
        font-size: 2.4rem;
        color: $blue;

        @include media(md) {
            font-size: 2.6rem;
        }
    }

    &__Meta {
        color: $grey-90;
    }

    &__Type {
        &::after {
            background-color: $blue;
        }
    }

    &__Author {
        &::before {
            background-color: $blue;
        }
    }
}

@import 'Styles/Pahlmans/includes';

/**
*
* EventSearchButtons
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButtons.Base.module';

.EventSearchButtons {
    $root: &;

    background-color: palette(black, 3);

    &__Container {
        @include media(md) {
            padding-bottom: 30px;
        }
    }

    &__List {
        @include media(md) {
            justify-content: left;

            #{$root}--Calendar &,
            :global(.PageArchiveSchool) & {
                justify-content: center;
            }
        }
    }
}

@import 'Styles/Dansskolanentre/includes';

/**
*
* GalleryModal
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'GalleryModal.Base.module';

.GalleryModal {
    $root: &;

    &__Container {
        padding-top: 80px;
        background-color: rgba(black, .9);

        @include media(md) {
            padding-top: 100px;
        }

        @include media(xl) {
            padding-top: 130px;
        }
    }

    &__ImageContainer {
        margin-bottom: 84px;
    }

    &__Image {
        margin-bottom: 10px;
    }

    &__ImageDescription {
        color: white;
        text-align: center;
    }

    &__Btn {
        background-color: white;

        &::before,
        &::after {
            content: '';
            width: 16px;
            background-color: $primary-color;
        }
    }

}

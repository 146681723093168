@import 'Styles/Medborgarskolan/includes';

/**
*
* EventSearchButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButton.Base.module.scss';

.EventSearchButton {
    $root: &;

    height: auto;
    margin: 0 0 12px 12px;

    &__Label {
        padding: 7px 16px 9px;
        font-weight: $medium;
        font-size: 1.6rem;
        line-height: 1.2;
        border-radius: 100px;
        border: 2px solid transparent;
        color: $blue;
        transition: border-color $transition;

        @include media(sm) {
            padding: 8px 22px 10px;
            font-size: 1.8rem;
        }

        #{$root}__Input:not(:checked) ~ &:hover {
            border: 2px solid $blue;
        }

        #{$root}__Input:checked ~ & {
            color: white;
            background: $blue;
        }
    }
}

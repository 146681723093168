@import 'Styles/Base/includes';

/**
*
* Gallery
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Gallery {
    $root: &;

    position: relative;
    transition: opacity .3s ease-in-out;

    &__Container {
        position: relative;
        margin: 35px 0;
    }

    &__Anchor {
        position: absolute;
        top: -120px;

        @include media(md) {
            top: -100px;
        }
    }

    &__Content {
        display: flex;
        flex-wrap: wrap;
        margin-left: -6px;
    }

    &__ImageContent {
        position: relative;
        width: calc(50% - 6px);
        margin-bottom: 6px;
        margin-left: 6px;
        text-decoration: none;
        cursor: pointer;
        border-radius: 1px;

        @include media(sm) {
            width: calc((100% / 3) - 6px);
        }

        &::before {
            content: '';
            display: block;
            height: 0;
            padding-bottom: calc(100% / (223 / 125));
            width: 100%;
        }

        &:nth-child(n+5) {
            display: none;

            @include media(sm) {
                display: block;
            }
        }

        #{$root}#{$root}--ShowMoreMobile & {
            &:nth-child(n+4) {
                display: none;

                @include media(sm) {
                    display: block;
                }
            }
        }

        #{$root}#{$root}--ShowMoreDesktop & {
            &:nth-child(n+6) {
                @include media(sm) {
                    display: none;
                }
            }
        }

        &--RestMobile,
        &--RestDesktop {
            position: relative;
            display: none !important;
        }

        &--RestMobile {
            #{$root}#{$root}--ShowMoreMobile & {
                display: block !important;

                @include media(sm) {
                    display: none !important;
                }
            }
        }

        &--RestDesktop {
            #{$root}#{$root}--ShowMoreDesktop & {
                display: none !important;

                @include media(sm) {
                    display: block !important;
                }
            }
        }
    }

    &__ShowMore {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        span:first-child {
            font-size: 1.8rem;
            line-height: 1.2;
            text-transform: uppercase;

            @include media(xs) {
                font-size: 2.6rem;
            }
        }

        span:last-child {
            font-size: 1.2rem;
            line-height: 1.2;
            text-transform: uppercase;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1px;
        overflow: hidden;

        :global {
            animation: fadeIn 1s ease forwards;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(#{$basepath}img/bg-icons/base/search--white.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;
            opacity: 0;
            transition: opacity .5s;
        }

        &:hover {
            &::after {
                opacity: 1;
            }

            @media(hover: none) {
                opacity: 0;
            }
        }
    }

    &__Modal {
        opacity: 0;
        transition: .3s ease-in-out;

        #{$root}--Open & {
            opacity: 1;
        }
    }

}

@import 'Styles/Pahlmans/includes';

/**
*
* EventSearchButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButton.Base.module.scss';

.EventSearchButton {
    $root: &;

    &__Label {
        color: $heading-color;
        font-weight: $normal;
        border: 1px solid palette(black, 15);
        border-radius: 4px;

        &:focus {
            outline: none;
        }

        #{$root}__Input:not(:checked) ~ &:hover {
            color: $secondary-color;
            border-color: $secondary-color;
        }

        #{$root}__Input:checked ~ & {
            background: $secondary-color;
            border: $secondary-color;
        }
    }
}

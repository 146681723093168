@import 'Styles/Pahlmans/includes';

/**
*
* LinkListImage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.LinkListImage {
    $root: &;

    padding-top: 40px;
    padding-bottom: 10px;
    background: white;

    @include media(md) {
        padding-top: 80px;
        padding-bottom: 45px;
    }

    &__Container {
        @include u-wrap(site);
    }

    &__Title {
        margin: 0 10px 35px;

        @include media(md) {
            margin: 0 0 40px;
        }
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        margin-top: -30px;
        margin-bottom: 35px;

        @include media(md) {
            margin-bottom: 40px;
        }
    }

    &__List {
        @include media(md) {
            display: flex;
            flex-flow: row wrap;
            margin-left: -32px;
        }

        @include media(lg) {
            margin-left: -40px;
        }
    }

    &__Item {
        display: flex;
        justify-content: stretch;
        position: relative;
        width: 100%;
        margin-bottom: 24px;
        border: 1px solid palette(black, 10);

        @include media(md) {
            margin-left: 32px;
            margin-bottom: 32px;
            width: calc(50% - 32px);
        }

        @include media(lg) {
            margin-left: 40px;
            margin-bottom: 40px;
            width: calc(50% - 40px);
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: block;
        z-index: z();
    }

    &__ItemContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex: 0 0 auto;

        @include media(xs) {
            flex-wrap: nowrap;
        }
    }

    &__ImageContainer {
        position: relative;
        width: 100%;
        height: 180px;
        flex: 1 1 auto;
        margin: -1px;
        overflow: hidden;

        @include media(xs) {
            width: 45%;
            max-width: 266px;
            height: auto;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Content {
        width: 100%;
        flex: 1 0 auto;
        padding: 24px;

        @include media(xs) {
            width: 55%;
        }

        @include media(sm) {
            padding: 32px;
        }
    }

    &__Tag {
        display: none;
    }

    &__ItemTitle {
        margin-bottom: 10px;
        font-weight: $medium;
        transition: color .3s ease-in-out;

        @include media(sm) {
            font-size: 2.8rem;
        }

        #{$root}__Item:hover & {
            color: $primary-color;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__ItemLabel {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding-right: 23px;
    }

    &__ItemText {
        font-size: 1.6rem;
        color: palette(black, 68);
    }

    &__Button {
        display: block;
        margin-top: 25px;
        background-image: $bgArrow;
        width: 23px;
        height: 15px;
        background-repeat: no-repeat;
        transition: transform .3s ease-in-out;

        svg,
        span {
            display: none;
        }

        #{$root}__Item:hover & {
            text-decoration: underline;
            transform: translateX(5px);

            @media(hover: none) {
                text-decoration: none;
                transform: none;
            }
        }
    }
}

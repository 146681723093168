@import 'Styles/Kulturama/includes';

/**
*
* EventSearchButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventSearchButton.Base.module.scss';

.EventSearchButton {
    $root: &;

    &__Label {
        font-weight: $medium;
        border: 1px solid transparent;
        border-radius: 4px;

        #{$root}__Input:not(:checked) ~ &:hover {
            color: palette(purple);
            border-color: palette(purple);
        }

        #{$root}__Input:checked ~ & {
            color: white;
            background: palette(purple);
        }
    }
}
